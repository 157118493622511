/*
 * 404 오류페이지
 */
import React from 'react'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import PageNotFoundImage from '@/assets/images/page_not_found.svg'
import DefaultLayout from '@/features/common/components/layouts/DefaultLayout'

interface IProps {}

const Page404: NextPage<IProps> = () => {
  const router = useRouter()

  const onClickGoHome = () => router.push('/').catch()

  return (
    <>
      <NextSeo
        title='페이지를 찾을 수 없습니다'
        description='죄송합니다. 페이지를 찾을 수 없습니다. 존재하지 않은 주소를 입력하셨거나 요청하신 페이지의 주소가 변경, 삭제되어 찾을 수 없습니다.'
      />
      <DefaultLayout>
        <main className='page-not-found'>
          <div className='wrap'>
            <PageNotFoundImage style={{ width: '260px', height: '180px' }} />
            <div className='content'>
              <h2>NOT FOUND</h2>
              <p>
                죄송합니다. 페이지를 찾을 수 없습니다.
                <br />
                존재하지 않은 주소를 입력하셨거나
                <br />
                요청하신 페이지의 주소가 변경, 삭제되어 찾을 수 없습니다.
              </p>
            </div>
            <div>
              <button type='button' title='홈으로 이동' aria-label='홈으로 이동' onClick={onClickGoHome}>
                홈으로 이동
              </button>
            </div>
          </div>
        </main>
      </DefaultLayout>
    </>
  )
}

export default Page404
